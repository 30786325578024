import { default as indexaUClWzF09lMeta } from "/usr/src/nuxt-app/pages/about-us/index.vue?macro=true";
import { default as berthnQCCAO6hhqMeta } from "/usr/src/nuxt-app/pages/admin/berth.vue?macro=true";
import { default as contactlEaL34xTQoMeta } from "/usr/src/nuxt-app/pages/admin/contact.vue?macro=true";
import { default as dashboardVUKjMJHlVJMeta } from "/usr/src/nuxt-app/pages/admin/dashboard.vue?macro=true";
import { default as newstuRIYHacmJMeta } from "/usr/src/nuxt-app/pages/admin/news.vue?macro=true";
import { default as projectslAR8sxP2KJMeta } from "/usr/src/nuxt-app/pages/admin/projects.vue?macro=true";
import { default as rentJ9jBigEN2HMeta } from "/usr/src/nuxt-app/pages/admin/rent.vue?macro=true";
import { default as sale5tIkiSQjAFMeta } from "/usr/src/nuxt-app/pages/admin/sale.vue?macro=true";
import { default as userswhWQzt0PRyMeta } from "/usr/src/nuxt-app/pages/admin/users.vue?macro=true";
import { default as login6Y49Qe3BVbMeta } from "/usr/src/nuxt-app/pages/auth/login.vue?macro=true";
import { default as _91id_93YWaqFlYVHTMeta } from "/usr/src/nuxt-app/pages/berths/[id].vue?macro=true";
import { default as index42hNtbMxTpMeta } from "/usr/src/nuxt-app/pages/berths/index.vue?macro=true";
import { default as indexiElVe6VRvqMeta } from "/usr/src/nuxt-app/pages/contact/index.vue?macro=true";
import { default as indexJIi8aNzFngMeta } from "/usr/src/nuxt-app/pages/crew-management/index.vue?macro=true";
import { default as indexAoBMx6PnC1Meta } from "/usr/src/nuxt-app/pages/index.vue?macro=true";
import { default as _91id_93qfljpTGDO4Meta } from "/usr/src/nuxt-app/pages/news/[id].vue?macro=true";
import { default as indexAqnIhg14ssMeta } from "/usr/src/nuxt-app/pages/news/index.vue?macro=true";
import { default as _91id_93Jwb03k39JdMeta } from "/usr/src/nuxt-app/pages/projects/[id].vue?macro=true";
import { default as indexKPbgvLXZYYMeta } from "/usr/src/nuxt-app/pages/projects/index.vue?macro=true";
import { default as indexcUhaaMOQFzMeta } from "/usr/src/nuxt-app/pages/yacht-management/index.vue?macro=true";
import { default as _91id_93CqPyFRkTIjMeta } from "/usr/src/nuxt-app/pages/yachts/rent/[id].vue?macro=true";
import { default as indexU4PARQjR1iMeta } from "/usr/src/nuxt-app/pages/yachts/rent/index.vue?macro=true";
import { default as _91id_93TXXvsgR1pOMeta } from "/usr/src/nuxt-app/pages/yachts/sale/[id].vue?macro=true";
import { default as indexElulquRO3LMeta } from "/usr/src/nuxt-app/pages/yachts/sale/index.vue?macro=true";
export default [
  {
    name: "about-us___en-US",
    path: "/en-US/about-us",
    component: () => import("/usr/src/nuxt-app/pages/about-us/index.vue")
  },
  {
    name: "about-us___fr-FR",
    path: "/about-us",
    component: () => import("/usr/src/nuxt-app/pages/about-us/index.vue")
  },
  {
    name: "about-us___it-IT",
    path: "/it-IT/about-us",
    component: () => import("/usr/src/nuxt-app/pages/about-us/index.vue")
  },
  {
    name: "admin-berth___en-US",
    path: "/en-US/admin/berth",
    meta: berthnQCCAO6hhqMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/berth.vue")
  },
  {
    name: "admin-berth___fr-FR",
    path: "/admin/berth",
    meta: berthnQCCAO6hhqMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/berth.vue")
  },
  {
    name: "admin-berth___it-IT",
    path: "/it-IT/admin/berth",
    meta: berthnQCCAO6hhqMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/berth.vue")
  },
  {
    name: "admin-contact___en-US",
    path: "/en-US/admin/contact",
    meta: contactlEaL34xTQoMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/contact.vue")
  },
  {
    name: "admin-contact___fr-FR",
    path: "/admin/contact",
    meta: contactlEaL34xTQoMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/contact.vue")
  },
  {
    name: "admin-contact___it-IT",
    path: "/it-IT/admin/contact",
    meta: contactlEaL34xTQoMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/contact.vue")
  },
  {
    name: "admin-dashboard___en-US",
    path: "/en-US/admin/dashboard",
    meta: dashboardVUKjMJHlVJMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/dashboard.vue")
  },
  {
    name: "admin-dashboard___fr-FR",
    path: "/admin/dashboard",
    meta: dashboardVUKjMJHlVJMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/dashboard.vue")
  },
  {
    name: "admin-dashboard___it-IT",
    path: "/it-IT/admin/dashboard",
    meta: dashboardVUKjMJHlVJMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/dashboard.vue")
  },
  {
    name: "admin-news___en-US",
    path: "/en-US/admin/news",
    meta: newstuRIYHacmJMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/news.vue")
  },
  {
    name: "admin-news___fr-FR",
    path: "/admin/news",
    meta: newstuRIYHacmJMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/news.vue")
  },
  {
    name: "admin-news___it-IT",
    path: "/it-IT/admin/news",
    meta: newstuRIYHacmJMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/news.vue")
  },
  {
    name: "admin-projects___en-US",
    path: "/en-US/admin/projects",
    meta: projectslAR8sxP2KJMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/projects.vue")
  },
  {
    name: "admin-projects___fr-FR",
    path: "/admin/projects",
    meta: projectslAR8sxP2KJMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/projects.vue")
  },
  {
    name: "admin-projects___it-IT",
    path: "/it-IT/admin/projects",
    meta: projectslAR8sxP2KJMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/projects.vue")
  },
  {
    name: "admin-rent___en-US",
    path: "/en-US/admin/rent",
    meta: rentJ9jBigEN2HMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/rent.vue")
  },
  {
    name: "admin-rent___fr-FR",
    path: "/admin/rent",
    meta: rentJ9jBigEN2HMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/rent.vue")
  },
  {
    name: "admin-rent___it-IT",
    path: "/it-IT/admin/rent",
    meta: rentJ9jBigEN2HMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/rent.vue")
  },
  {
    name: "admin-sale___en-US",
    path: "/en-US/admin/sale",
    meta: sale5tIkiSQjAFMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/sale.vue")
  },
  {
    name: "admin-sale___fr-FR",
    path: "/admin/sale",
    meta: sale5tIkiSQjAFMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/sale.vue")
  },
  {
    name: "admin-sale___it-IT",
    path: "/it-IT/admin/sale",
    meta: sale5tIkiSQjAFMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/sale.vue")
  },
  {
    name: "admin-users___en-US",
    path: "/en-US/admin/users",
    meta: userswhWQzt0PRyMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/users.vue")
  },
  {
    name: "admin-users___fr-FR",
    path: "/admin/users",
    meta: userswhWQzt0PRyMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/users.vue")
  },
  {
    name: "admin-users___it-IT",
    path: "/it-IT/admin/users",
    meta: userswhWQzt0PRyMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/users.vue")
  },
  {
    name: "auth-login___en-US",
    path: "/en-US/auth/login",
    meta: login6Y49Qe3BVbMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/auth/login.vue")
  },
  {
    name: "auth-login___fr-FR",
    path: "/auth/login",
    meta: login6Y49Qe3BVbMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/auth/login.vue")
  },
  {
    name: "auth-login___it-IT",
    path: "/it-IT/auth/login",
    meta: login6Y49Qe3BVbMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/auth/login.vue")
  },
  {
    name: "berths-id___en-US",
    path: "/en-US/berths/:id()",
    component: () => import("/usr/src/nuxt-app/pages/berths/[id].vue")
  },
  {
    name: "berths-id___fr-FR",
    path: "/berths/:id()",
    component: () => import("/usr/src/nuxt-app/pages/berths/[id].vue")
  },
  {
    name: "berths-id___it-IT",
    path: "/it-IT/berths/:id()",
    component: () => import("/usr/src/nuxt-app/pages/berths/[id].vue")
  },
  {
    name: "berths___en-US",
    path: "/en-US/berths",
    component: () => import("/usr/src/nuxt-app/pages/berths/index.vue")
  },
  {
    name: "berths___fr-FR",
    path: "/berths",
    component: () => import("/usr/src/nuxt-app/pages/berths/index.vue")
  },
  {
    name: "berths___it-IT",
    path: "/it-IT/berths",
    component: () => import("/usr/src/nuxt-app/pages/berths/index.vue")
  },
  {
    name: "contact___en-US",
    path: "/en-US/contact",
    component: () => import("/usr/src/nuxt-app/pages/contact/index.vue")
  },
  {
    name: "contact___fr-FR",
    path: "/contact",
    component: () => import("/usr/src/nuxt-app/pages/contact/index.vue")
  },
  {
    name: "contact___it-IT",
    path: "/it-IT/contact",
    component: () => import("/usr/src/nuxt-app/pages/contact/index.vue")
  },
  {
    name: "crew-management___en-US",
    path: "/en-US/crew-management",
    component: () => import("/usr/src/nuxt-app/pages/crew-management/index.vue")
  },
  {
    name: "crew-management___fr-FR",
    path: "/crew-management",
    component: () => import("/usr/src/nuxt-app/pages/crew-management/index.vue")
  },
  {
    name: "crew-management___it-IT",
    path: "/it-IT/crew-management",
    component: () => import("/usr/src/nuxt-app/pages/crew-management/index.vue")
  },
  {
    name: "index___en-US",
    path: "/en-US",
    component: () => import("/usr/src/nuxt-app/pages/index.vue")
  },
  {
    name: "index___fr-FR",
    path: "/",
    component: () => import("/usr/src/nuxt-app/pages/index.vue")
  },
  {
    name: "index___it-IT",
    path: "/it-IT",
    component: () => import("/usr/src/nuxt-app/pages/index.vue")
  },
  {
    name: "news-id___en-US",
    path: "/en-US/news/:id()",
    component: () => import("/usr/src/nuxt-app/pages/news/[id].vue")
  },
  {
    name: "news-id___fr-FR",
    path: "/news/:id()",
    component: () => import("/usr/src/nuxt-app/pages/news/[id].vue")
  },
  {
    name: "news-id___it-IT",
    path: "/it-IT/news/:id()",
    component: () => import("/usr/src/nuxt-app/pages/news/[id].vue")
  },
  {
    name: "news___en-US",
    path: "/en-US/news",
    component: () => import("/usr/src/nuxt-app/pages/news/index.vue")
  },
  {
    name: "news___fr-FR",
    path: "/news",
    component: () => import("/usr/src/nuxt-app/pages/news/index.vue")
  },
  {
    name: "news___it-IT",
    path: "/it-IT/news",
    component: () => import("/usr/src/nuxt-app/pages/news/index.vue")
  },
  {
    name: "projects-id___en-US",
    path: "/en-US/projects/:id()",
    component: () => import("/usr/src/nuxt-app/pages/projects/[id].vue")
  },
  {
    name: "projects-id___fr-FR",
    path: "/projects/:id()",
    component: () => import("/usr/src/nuxt-app/pages/projects/[id].vue")
  },
  {
    name: "projects-id___it-IT",
    path: "/it-IT/projects/:id()",
    component: () => import("/usr/src/nuxt-app/pages/projects/[id].vue")
  },
  {
    name: "projects___en-US",
    path: "/en-US/projects",
    component: () => import("/usr/src/nuxt-app/pages/projects/index.vue")
  },
  {
    name: "projects___fr-FR",
    path: "/projects",
    component: () => import("/usr/src/nuxt-app/pages/projects/index.vue")
  },
  {
    name: "projects___it-IT",
    path: "/it-IT/projects",
    component: () => import("/usr/src/nuxt-app/pages/projects/index.vue")
  },
  {
    name: "yacht-management___en-US",
    path: "/en-US/yacht-management",
    component: () => import("/usr/src/nuxt-app/pages/yacht-management/index.vue")
  },
  {
    name: "yacht-management___fr-FR",
    path: "/yacht-management",
    component: () => import("/usr/src/nuxt-app/pages/yacht-management/index.vue")
  },
  {
    name: "yacht-management___it-IT",
    path: "/it-IT/yacht-management",
    component: () => import("/usr/src/nuxt-app/pages/yacht-management/index.vue")
  },
  {
    name: "yachts-rent-id___en-US",
    path: "/en-US/yachts/rent/:id()",
    component: () => import("/usr/src/nuxt-app/pages/yachts/rent/[id].vue")
  },
  {
    name: "yachts-rent-id___fr-FR",
    path: "/yachts/rent/:id()",
    component: () => import("/usr/src/nuxt-app/pages/yachts/rent/[id].vue")
  },
  {
    name: "yachts-rent-id___it-IT",
    path: "/it-IT/yachts/rent/:id()",
    component: () => import("/usr/src/nuxt-app/pages/yachts/rent/[id].vue")
  },
  {
    name: "yachts-rent___en-US",
    path: "/en-US/yachts/rent",
    component: () => import("/usr/src/nuxt-app/pages/yachts/rent/index.vue")
  },
  {
    name: "yachts-rent___fr-FR",
    path: "/yachts/rent",
    component: () => import("/usr/src/nuxt-app/pages/yachts/rent/index.vue")
  },
  {
    name: "yachts-rent___it-IT",
    path: "/it-IT/yachts/rent",
    component: () => import("/usr/src/nuxt-app/pages/yachts/rent/index.vue")
  },
  {
    name: "yachts-sale-id___en-US",
    path: "/en-US/yachts/sale/:id()",
    component: () => import("/usr/src/nuxt-app/pages/yachts/sale/[id].vue")
  },
  {
    name: "yachts-sale-id___fr-FR",
    path: "/yachts/sale/:id()",
    component: () => import("/usr/src/nuxt-app/pages/yachts/sale/[id].vue")
  },
  {
    name: "yachts-sale-id___it-IT",
    path: "/it-IT/yachts/sale/:id()",
    component: () => import("/usr/src/nuxt-app/pages/yachts/sale/[id].vue")
  },
  {
    name: "yachts-sale___en-US",
    path: "/en-US/yachts/sale",
    component: () => import("/usr/src/nuxt-app/pages/yachts/sale/index.vue")
  },
  {
    name: "yachts-sale___fr-FR",
    path: "/yachts/sale",
    component: () => import("/usr/src/nuxt-app/pages/yachts/sale/index.vue")
  },
  {
    name: "yachts-sale___it-IT",
    path: "/it-IT/yachts/sale",
    component: () => import("/usr/src/nuxt-app/pages/yachts/sale/index.vue")
  }
]